.Loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #eee;
    display:flex;
    justify-content: center;
    align-items: center;
    /* z-index: 900; */
  }

  .Loader>span {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    box-sizing: border-box;
    border: 4px solid var(--loader-color);
    animation: loader 2s infinite ease;
  }

.Loader>span>span {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: var(--loader-color);
    animation: loader-inner 2s infinite ease-in;
  }

  @keyframes loader {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(180deg);}
    50% { transform: rotate(180deg);}
    75% { transform: rotate(360deg);}
    100% { transform: rotate(360deg);}
  }
  
  @keyframes loader-inner {
    0% { height: 0%;}
    25% { height: 0%;}
    50% { height: 100%;}
    75% { height: 100%;}
    100% { height: 0%;}
  }