/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */

.RadioGroup {
  border: solid 1px rgb(34, 34, 34);
  display: inline-block !important;
  /* margin: 20px; */
  border-radius: 4px;
  overflow: hidden;
  width:max-content !important;
  flex:none !important;
}

.RadioGroup input[type=radio] {
  position: absolute;
  visibility: hidden;
  display: none;
  opacity: 0;
}

.RadioGroup label {
  /* color: #9a929e; */
  color: rgb(34, 34, 34);
  display: inline-block;
  cursor: pointer;
  padding: .2rem .5rem;
}

.RadioGroup span {
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  float: left;
}

.RadioGroup input[type=radio]:checked+label {
  color: #ccc8ce;
  background: rgb(34, 34, 34);
  transition: background 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

.RadioGroup span+span {
  border-left: solid 1px rgb(34, 34, 34);
}

@media (max-width: 768px) {
  .RadioGroup label {
    padding: .2rem .2rem;
    font-size: .81rem;
  }
}