
.Loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #eee; */
    /* background-color: rgba(255, 255, 255, 0.7);     */
    background-color: rgba(0, 0, 0, 0.3);    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}