body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Open Sans", sans-serif, "Lato";
  font-style: normal;
  /* font-feature-settings: 'calt' off, 'liga' off; */
}

.Select.is-open {
  z-index: 1000 !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.bg-tb-head {
  background-color: var(--muted-color);
}
#sideDrawer ul li a:hover:not(#menu) {
  border-radius: 10px;
  background-color: var(--bg-pink);
}
#sideDrawer ul li a:hover:not(#menu) span {
  color: var(--primary-color) !important;
}
#sideDrawer ul li a:hover:not(#menu) svg path {
  fill: var(--primary-color) !important;
}
.active {
  border-radius: 10px;
  background-color: var(--bg-pink) !important;
  /* border-left: 7px solid var(--primary-color); */
}
.active span,
.active a {
  color: var(--primary-color) !important;
}
.sub_link:hover {
  color: var(--primary-color) !important;
}
/* #sideDrawer {
  border-right: 1px solid #d7d7d7;
} */

.quick-card {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  flex-basis: calc(50% - 16px);
  margin-bottom: 17px;
  width: 120px;
  height: 110px;
  padding: 8px !important;
  cursor: pointer;
}
.quick-margin {
  margin-top: 25%;
}
.type-card {
  flex-basis: calc(50% - 16px);
  /* Distribute cards evenly in a row */
  margin-bottom: 17px;
  /* Add some vertical spacing between cards */
  border: 1px solid #ccc;
  width: 200px;
  height: 160px;
  padding: 8px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  cursor: pointer;
}

.new-types-card {
  background: var(--primary-color);
  color: #fff !important;
  border-radius: 10px;
  flex-basis: calc(50% - 16px);
  margin-bottom: 17px;
  /* Add some vertical spacing between cards */
  width: 200px;
  height: 160px;
  padding: 20px !important;
  cursor: pointer;
}

.smart-card {
  background: var(--primary-color);
  color: #fff !important;
  border-radius: 10px;
  /* flex-basis: calc(50% - 16px); */
  margin-bottom: 17px;
  /* Add some vertical spacing between cards */
  width: 190px;
  min-width: 190px;
  /* height: 125px; */
  padding: 16px !important;
  cursor: pointer;
}

.text-small-height {
  height: 20px;
}

.color-white {
  color: #fff !important;
}

.parts-inc {
  display: flex;
  height: 56px;
  width: 130px;
  border-radius: 0px 15px 15px 0px;
  align-items: center;
  justify-content: space-around;
}

.round-button {
  border-radius: 50%;
  width: 15px;
  display: flex;
  justify-content: center;
}

.check {
  appearance: none;
  height: 22px;
  width: 22px;
  position: relative;
  display: flex;
  padding-top: 1px;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  transition: 0.1s;
  background-color: #fc665c !important;
  text-align: center;
  font-weight: 600;
  color: white;
  border-radius: 50% !important;
  outline: none !important;
}

.check:checked {
  background-color: #99d77b !important;
}

.check:before {
  /* content: "✖"; */
  content: url("/public/repair/close.png");
}

.check:checked:before {
  /* content: '✔'; */
  content: url("/public/repair/check.png");

  /* content: '✓'; */
  /* content: '✅'; */
}

.check:hover {
  cursor: pointer;
  /* opacity: 0.8; */
}

input.uk-checkbox:checked {
  background-color: var(--primary-color);
}

input.uk-checkbox:checked:focus {
  background-color: var(--primary-color);
}

.uk-checkbox:focus {
  border-color: var(--primary-color);
}

.bg-pink {
  background-color: var(--bg-pink) !important;
}

.pointer {
  cursor: pointer;
}

.vertical_scroll {
  height: 76vh;
  overflow-y: auto;
}

.color-black {
  color: var(--black-color) !important;
}

.custom_width {
  width: var(--width);
}

.uk-modal {
  padding: 0px !important;
  margin: 0px !important;
}

.uk-text-medium {
  font-size: 16px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-10 {
  border-radius: 10px;
}

.bg-main-modal {
  background: #faf6fc !important;
}

.fit-height {
  height: fit-content;
}

.no-border {
  /* border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important; */
  border: none !important;
  border-color: transparent !important;
}

.padding-10 {
  padding: 5px;
}

.discount_slider {
  /* width: 100%; */
  padding: 10px;
  overflow-x: auto;
}

/* .discount_slider::-webkit-scrollbar {
  display: none;
} */
.discount_span {
  padding: 8px 14px 8px 14px;
  border-radius: 14px;
  background-color: lightgrey;
  color: black;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 8px;
}

.active_discount_span,
.discount_span:hover {
  background-color: var(--bg-pink);
  color: var(--primary-color);
}

.bg-white {
  background: #fff;
}

.borderless_input,
.borderless_input:focus {
  border: none;
  background-color: none;
  outline: 0;
}
.search:focus {
  border: 1px solid var(--muted-color);
}
.border-default {
  /* border: 1px solid black; */
  border: 1px solid #cbcbd4;
}

.icon {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: var(--white-color);
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}

.icon-button__badge {
  position: absolute;
  bottom: -6px;
  right: -7px;
  width: 18px;
  height: 18px;
  background: var(--white-color);
  /* color: var(--white-color); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pin_default {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c2c2c2;
  /* margin-left: 7px; */
}

.pin_active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  margin-left: 7px;
}

.default_pin_btn {
  border-radius: 15px;
  background: var(--white-color);
  box-shadow: 0px 10px 24px 0px rgba(179, 182, 186, 0.3);
  font-size: 15px;
  padding: 0 35px;
}
.disable_key {
  color: #a4a4a4;
  border-radius: 15px;
  background-color: #d9d3d3;
  font-size: 10px;
  box-shadow: 0px 10px 24px 0px rgba(179, 182, 186, 0.3);
  padding: 0 27px;
  pointer-events: none;
}
.active_key {
  color: var(--white-color);
  background-color: var(--primary-color);
  font-size: 12px;
  border-radius: 15px;
  padding: 0 27px;
  /* box-shadow: 0px 10px 24px 0px rgba(179, 182, 186, 0.30); */
}
.color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: var(--primary-color);
  /* margin-right: 10px; */
}
.color-box-small {
  display: inline-block;
  min-width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: var(--primary-color);
}
::-webkit-scrollbar {
  width: 10px;
}

/*  
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  
} */

::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

@media only screen and (max-width: 1240px) {
  .custom_width {
    width: 100%;
    margin-top: 8px;
  }
}

@media only screen and (min-width: 1600px) {
  .vertical_scroll {
    height: 80vh !important;
  }
}

@media (min-width: 768px) {
  .tm-flex-medium {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .tm-flex-top {
    align-items: flex-start;
  }
}
